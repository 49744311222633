import React, { Component } from "react";
// import ParticlesBg from "particles-bg";
import Fade from "react-awesome-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    // const project = this.props.data.project;
    // const github = this.props.data.github;
    const name = this.props.data.name;
    const description = this.props.data.description;

    return (
      <header id="home">
        {/* <ParticlesBg type="circle" bg={true} /> */}

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
              Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About us
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#resume">
                Prices
              </a>
            </li>
        

            <li>
              <a className="smoothscroll" href="#portfolio">
                Photos
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
              Contact
              </a>
            </li>
            <li>
              <a  href="https://en.solbjergskovbnb.dk">
              English
              </a>
            </li>

            <li>
              <a href="https://www.solbjergskovbnb.dk">
              Dansk
              </a>
            </li> 
            {/* <li>
            <a href="https://booking.solbjergskovbnb.dk/accommodations/">Click To Book</a> 
            </li>  */}
          </ul>
        </nav>
        

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline" style={{ color: 'white' }}  >{name } </h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h2 style={{ color: 'white' }} >It is the ideal place to relax after a day of hard work or long journey. <br/> Call +45 2988 8167 to book</h2>
            </Fade>
            {/* <Fade bottom  duration={1200}>
            <a href="https://booking.solbjergskovbnb.dk/accommodations/"> <h1>Click To Book </h1></a> 
            </Fade> */}
            <hr />
            {/* <Fade bottom duration={2000}>
              <ul className="social">
                <a href={project} className="button btn project-btn">
                  <i className="fa fa-book"></i>Project
                </a>
                <a href={github} className="button btn github-btn">
                  <i className="fa fa-github"></i>Github
                </a>
              </ul>
            </Fade> */}
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
