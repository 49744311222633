import React, { Component } from "react";
// import Zmage from "react-zmage";
// import Fade from "react-reveal";
// import ImageGallery from 'react-image-gallery';
import DztImageGalleryComponent from "reactjs-image-gallery";
// let id = 0;
// import { Carousel } from 'react-responsive-carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
const data = [
    {
      url: 'images/portfolio/BNB_pic2.jpg',
      thumbUrl:'images/portfolio/resize/BNB_pic2.jpg',
      title: "Entrance",
      
    },
    {
        url: 'images/portfolio/car_park.jpg',
        thumbUrl:'images/portfolio/resize/car_park.jpg',
        title: "Car Park",
        
      },
    {
        url: 'images/portfolio/hallway_updated.png',
        thumbUrl:  'images/portfolio/resize/hallway_updated.png',
        title: "Hallway",
    },
    {
        url: 'images/portfolio/new_room1_1.jpg',
        thumbUrl:  'images/portfolio/resize/new_room1_1.jpg',
        title: "Room 1",
    },
    {
        url: 'images/portfolio/new_room1_2.jpg',
        thumbUrl:  'images/portfolio/resize/new_room1_2.jpg',
        title: "Room 1, another view",
    },
    {
        url: 'images/portfolio/room_2_1.png',
        thumbUrl:  'images/portfolio/resize/room_2_1.png',
        title: "Room 2",
    },
    {
        url: 'images/portfolio/room2_2.png',
        thumbUrl:  'images/portfolio/resize/room2_2.png',
        title: "Room 2, another view",
    },
    {
        url: 'images/portfolio/v3_1.png',
        thumbUrl:  'images/portfolio/resize/v3_1.png',
        title: "Room 3",
    },
    {
        url: 'images/portfolio/v3_2.png',
        thumbUrl:  'images/portfolio/resize/v3_2.png',
        title: "Room 3, another view",
    }
    ,
    {
        url: 'images/portfolio/room_5_1.png',
        thumbUrl:  'images/portfolio/resize/room_5_1.png',
        title: "Room 5",
    }
    ,
    {
        url: 'images/portfolio/room_5_2.png',
        thumbUrl:  'images/portfolio/resize/room_5_2.png',
        title: "Room 5, another view",
    }
    ,
    {
        url: 'images/portfolio/room5_two_single_beds_view1.jpg',
        thumbUrl:  'images/portfolio/resize/room5_two_single_beds_view1.jpg',
        title: "Room 5, with 2 single beds",
    }
    ,
    {
        url: 'images/portfolio/room5_two_single_beds_view2.jpg',
        thumbUrl:  'images/portfolio/resize/room5_two_single_beds_view2.jpg',
        title: "Room 5, with 2 single beds, another view",
    }
    ,
    {
        url: 'images/portfolio/room5_two_single_beds_view3.jpg',
        thumbUrl:  'images/portfolio/resize/room5_two_single_beds_view3.jpg',
        title: "Room 5, with 2 single beds, another view",
    }
    ,
    {
        url: 'images/portfolio/room_6_1.png',
        thumbUrl:  'images/portfolio/resize/room_6_1.png',
        title: "Room 6",
    }
    ,
    {
        url: 'images/portfolio/room_6_2.png',
        thumbUrl:  'images/portfolio/resize/room_6_2.png',
        title: "Room 6, another view",
    },
    {
        url: 'images/portfolio/bad_1.png',
        thumbUrl:  'images/portfolio/resize/bad_1.png',
        title: "Bathroom",
    },
    {
        url: 'images/portfolio/bad_2.png',
        thumbUrl:  'images/portfolio/resize/bad_2.png',
        title: "Bathroom, another view",
    },
    {
        url: 'images/portfolio/bad_3.png',
        thumbUrl:  'images/portfolio/resize/bad_3.png',
        title: "Bathroom, toilet",
    },
    {
        url: 'images/portfolio/bad_shower.jpg',
        thumbUrl:  'images/portfolio/resize/bad_shower.jpg',
        title: "Bathroom, shower",
    },
    {
        url: 'images/portfolio/second_bathroom_1.png',
        thumbUrl:  'images/portfolio/resize/second_bathroom_1.png',
        title: "Second bathroom",
    },
    {
        url: 'images/portfolio/second_bathroom_2.png',
        thumbUrl:  'images/portfolio/resize/second_bathroom_2.png',
        title: "Second bathroom, another view",
    },
    {
        url: 'images/portfolio/kokken_1.png',
        thumbUrl:  'images/portfolio/resize/kokken_1.png',
        title: "Kitchen",
    },
 
    {
        url: 'images/portfolio/dining_room_1.png',
        thumbUrl:  'images/portfolio/resize/dining_room_1.png',
        title: "Dining area",
    },
    {
        url: 'images/portfolio/dining_room_2.png',
        thumbUrl:  'images/portfolio/resize/dining_room_2.png',
        title: "Dining area, another view",
    },
  
    {
        url: 'images/portfolio/BNB_pic6.jpg',
        thumbUrl:  'images/portfolio/resize/BNB_pic6.jpg',
        title: "Landscape",
    },

    {
        url: 'images/portfolio/have1_1.jpg',
        thumbUrl:  'images/portfolio/resize/have1_1.jpg',
        title: "Garden",
    },
    {
        url: 'images/portfolio/have1_2.jpg',
        thumbUrl:  'images/portfolio/resize/have1_2.jpg',
        title: "Garden",
    },
    {
        url: 'images/portfolio/have1_3.jpg',
        thumbUrl:  'images/portfolio/resize/have1_3.jpg',
        title: "Garden",
    },
    {
        url: 'images/portfolio/have1_4.jpg',
        thumbUrl:  'images/portfolio/resize/have1_4.jpg',
        title: "Garden",
    },
    {
        url: 'images/portfolio/have1_5.jpg',
        thumbUrl:  'images/portfolio/resize/have1_5.jpg',
        title: "Garden",
    },
    {
        url: 'images/portfolio/have1_6.jpg',
        thumbUrl:  'images/portfolio/resize/have1_6.jpg',
        title: "Garden",
    },
    {
        url: 'images/portfolio/have1_7.jpg',
        thumbUrl:  'images/portfolio/resize/have1_7.jpg',
        title: "Garden",
    },
    {
        url: 'images/portfolio/have1_8.jpg',
        thumbUrl:  'images/portfolio/resize/have1_8.jpg',
        title: "Garden",
    },
    {
        url: 'images/portfolio/deer.png',
        thumbUrl:  'images/portfolio/resize/deer.png',
        title: "Deer",
    },
    {
        url: 'images/portfolio/night1.jpg',
        thumbUrl:  'images/portfolio/resize/night1.jpg',
        title: "Entrance at night",
    },
    {
        url: 'images/portfolio/night2.jpg',
        thumbUrl:  'images/portfolio/resize/night2.jpg',
        title: "Drive-way at night",
    }
    ,
    {
        url: 'images/portfolio/night3.jpg',
        thumbUrl:  'images/portfolio/resize/night3.jpg',
        title: "B&B building at night",
    }
    ,
    {
        url: 'images/portfolio/night4.jpg',
        thumbUrl:  'images/portfolio/resize/night4.jpg',
        title: "Courtyard at night",
    }
    ,
    {
        url: 'images/portfolio/frugt1.jpg',
        thumbUrl:  'images/portfolio/resize/frugt1.jpg',
        title: "Fruit",
    }
    ,
    {
        url: 'images/portfolio/frugt2.jpg',
        thumbUrl:  'images/portfolio/resize/frugt2.jpg',
        title: "Fruit",
    }
    ,
    {
        url: 'images/portfolio/frugt3.jpg',
        thumbUrl:  'images/portfolio/resize/frugt3.jpg',
        title: "Fruit",
    }
    ,
    {
        url: 'images/portfolio/Have20210509_1.jpg',
        thumbUrl:  'images/portfolio/resize/Have20210509_1.jpg',
        title: "Garden",
    }
    ,
    {
        url: 'images/portfolio/Have20210509_2.jpg',
        thumbUrl:  'images/portfolio/resize/Have20210509_2.jpg',
        title: "Garden",
    }
    ,
    {
        url: 'images/portfolio/Have20210509_3.jpg',
        thumbUrl:  'images/portfolio/resize/Have20210509_3.jpg',
        title: "Garden",
    },
    {
        url: 'images/portfolio/deer_20211229.jpg',
        thumbUrl:  'images/portfolio/resize/deer_20211229.jpg',
        title: "Deer in garden",
    },
    {
        url: 'images/portfolio/campfire_image0.png',
        thumbUrl:  'images/portfolio/resize/campfire_image0.png',
        title: "Campfire, view 1",
    },
    {
        url: 'images/portfolio/campfire_image1.png',
        thumbUrl:  'images/portfolio/resize/campfire_image1.png',
        title: "Campfire, view 2",
    },
    {
        url: 'images/portfolio/campfire_image2.png',
        thumbUrl:  'images/portfolio/resize/campfire_image2.png',
        title: "Campfire, view 3",
    },
    {
        url: 'images/portfolio/campfire_image3.png',
        thumbUrl:  'images/portfolio/resize/campfire_image3.png',
        title: "Campfire, view 4",
    }
    ,
    {
        url: 'images/portfolio/terrace_1.png',
        thumbUrl:  'images/portfolio/resize/terrace_1_resize.png',
        title: "Terrace, view 1",
    },
    {
        url: 'images/portfolio/terrace_2.png',
        thumbUrl:  'images/portfolio/resize/terrace_2_resize.png',
        title: "Terrace, view 2",
    }
    
    
  ];


class Portfolio2 extends Component {
  render() {
    if (!this.props.data) return null;

    
     

   

    return (
      <section id="portfolio">
    
    <div>
        <DztImageGalleryComponent images={data} />
      </div>
           
      </section>
    );
  }
}

export default Portfolio2;
