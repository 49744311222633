import React, { Component } from "react";
import Fade from "react-awesome-reveal";
// import GoogleMapReact from 'google-map-react';



const AnyReactComponent = ({ text }) => <div>{text}</div>;
class About extends Component {
  static defaultProps = {
    center: {
      lat: 56.059037738943545,
      lng: 10.10227380626781
    },
    zoom: 14
  };

  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    // const resumeDownload = this.props.data.resumedownload;
    
    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Solbjerg Skov B&B"
              />
            </div>
            <div className="nine columns main-col">
              <h2>About us</h2>

              <p>{bio}</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>Contact and Booking</h2>
                  <p className="address">
             
                    <span>{name}</span>
                    <br />
                    <span>CVR: 44830574</span>
                    <br />
                    <span>
                      {street}
                      <br />
                      {city} {state}, {zip}
                    </span>
                    <br />
                    <span>{phone}</span>
                    <br />
                    <span>{email}</span>
                  </p>
                </div>
                {/* <div className="columns download">
                  <p>
                    <a href={resumeDownload} className="button">
                      <i className="fa fa-download"></i>Download Resume
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>

     
        
      {/* <div style={{ height: '25vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAh4cptgDetIFhoDAItg1sx5Ezqd1YKRoc" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={56.059037738943545}
            lng={10.10227380626781}
            text="Solbjerg Skov B&B"
          />
        </GoogleMapReact>
      </div> */}
        </Fade>
        
      </section>
    );
  }
}

export default About;
